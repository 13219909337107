#aboutme-bg {
    background-image: url("/src/media/PB_BG_01.png");
    padding-bottom: 6rem;
}


@media (max-width: 1100px) {
    #aboutme-bg {
        background-image: url("/src/media/PB_BG_01.png");
        background-position-y: -10rem;
        background-position-x: -30rem;
        padding-bottom: 6rem;
    }
}

@media (max-width: 1000px) {
    #aboutme-bg {
        background-image: url("/src/media/PB_BG_01.png");
        background-position-y: 20rem;
        background-position-x: -30rem;
        background-size: cover;
        padding-bottom: 6rem;
    }
}

@media (min-width: 2560px){
    #aboutme-bg {
        background-image: url("/src/media/PB_BG_01.png");
        background-position-y: -8rem;
        background-position-x: 10rem;
        padding-bottom: 6rem;
    }
}
@media (min-width: 1920px) and (max-width:1920px){
    #aboutme-bg {
        background-image: url("/src/media/PB_BG_01.png");
        background-position-y: -14rem;
        background-position-x: -6rem;
        padding-bottom: 6rem;
    }
}
