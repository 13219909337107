@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    background-color: #000;
    margin: 0;
    font-family: 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

#Home {
    margin: auto;
    max-width: 2560px;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

video {
    max-width: unset;
    height: unset;
    height: 100%;
    left: 0;
    object-fit: cover;
    position: absolute;
    top: 0;
    width: 100%;
}

img {
    max-width: unset;
    height: unset;
}

.carousel-item {
}